<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>Data</b-card-title>
                <b-card-title class="text-primary">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        :to="{name: 'mstPemeriksaanMorphologyTambah'}"
                    >
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </b-card-title>
            </b-card-header>

            <b-card-body>
                <b-row>
                    <b-col cols="12">
                        <!-- search input -->
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div class="d-flex align-items-center">
                                    <label class="mr-1">Cari</label>
                                    <b-form-input
                                        v-model="searchTerm"
                                        placeholder="Cari"
                                        type="text"
                                        class="d-inline-block"
                                        @input="handleSearch"
                                    />
                                </div>
                            </b-form-group>
                        </div>
                        <!-- table -->
                        <vue-good-table
                            :columns="columns"
                            :rows="rows"
                            :search-options="{
                                enabled: true,
                                externalQuery: searchTerm }"
                            :pagination-options="{
                                enabled: true,
                                perPage:row,
                                jumpFirstOrLast : true,
                            }"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            mode="remote"
                            @on-sort-change="onSortChange"
                        >
                            <template
                                slot="table-row"
                                slot-scope="props"
                            >
                                <!-- Column: aksi -->
                                <span v-if="props.column.field === 'aksi'">
                                    <span>
                                        <b-dropdown
                                            variant="link"
                                            toggle-class="text-decoration-none"
                                            no-caret
                                        >
                                            <template v-slot:button-content>
                                                <feather-icon
                                                    icon="MoreVerticalIcon"
                                                    size="16"
                                                    class="text-body align-middle mr-25"
                                                />
                                            </template>
                                            <b-dropdown-item :to="{name: 'mstPemeriksaanMorphologyUbah', params: {id: props.row.id}}">
                                                <feather-icon
                                                    icon="Edit2Icon"
                                                    class="mr-50"
                                                />
                                                <span>Ubah</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="deleteButton(props.row.id)">
                                                <feather-icon
                                                    icon="TrashIcon"
                                                    class="mr-50"
                                                />
                                                <span>Hapus</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                </span>

                                <!-- Column: penjelasan -->
                                <span v-else-if="props.column.field === 'penjelasan'" v-html="props.formattedRow[props.column.field]">
                                </span>

                                <!-- Column: Common -->
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>

                            <!-- pagination -->
                            <template
                                slot="pagination-bottom"
                            >
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap ">
                                            Menampilkan 1 hingga
                                        </span>
                                        <b-form-select
                                            v-model="row"
                                            :options="['10','100','1000']"
                                            class="mx-1"
                                            @input="handlePageChange"
                                        />
                                        <span class="text-nowrap"> dari {{ totalRecords }} entri </span>
                                    </div>
                                    <div>
                                        <b-pagination
                                            :value="1"
                                            :total-rows="totalRecords"
                                            :per-page="row"
                                            first-number
                                            last-number
                                            align="right"
                                            prev-class="prev-item"
                                            next-class="next-item"
                                            class="mt-1 mb-0"
                                            @change="handleChangePage"
                                        >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
  
</template>

<script>
import {
    BRow, BCol, BCard, BCardText, BFormInput, BAvatar, BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardTitle, BCardBody, BButton, BFormGroup, BFormSelect
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import {ref } from '@vue/composition-api'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
    setup(props, context){
        //global variable
        const g = context.root
        const $http = g.$http

        //variable untuk table
        let rows = ref([])
        let searchTerm = ref('')
        let start = ref(1)
        let row = ref(10)
        let totalRecords = ref(0)
        let sortColumn = ref('')
        let order = ref('')
        let isLoading = ref(false)

        //fungsi ambil data untuk table
        const datatable = () => {
            isLoading.value = true
            $http({
                url: '/mst/pemeriksaan/morphology/get-data',
                params: {
                    start: start.value-1,
                    row: row.value,
                    search: searchTerm.value,
                    sortColumn: sortColumn.value,
                    order: order.value
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    isLoading.value = false
                    rows.value = res.data.data
                    totalRecords.value = res.data.totalRecord
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Table`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        datatable()

        const deleteButton = (id) => {
            g.$swal({
                title: 'Apakah Anda Yakin?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    $http({
                        url: '/mst/pemeriksaan/morphology/'+id,
                        method: 'delete',
                        headers: {
                            Authorization: 'Bearer '+g.$cookies.get('token')
                        }
                    })
                        .then(() => {
                            datatable()
                        })
                        .catch(err => {
                            let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                            g.$bvToast.toast(showError || 'Something Wrong', {
                                title: `Error Load Table`,
                                variant: 'danger',
                                solid: true,
                            })
                        })
                }
            })
        }

        const handleSearch = (searching) => {
            start.value = 1
            datatable()
        }
        
        const handlePageChange = (active) => {
            start.value = 1
            datatable()
        }

        const handleChangePage = (page) =>  {
            start.value = (page-1)*row.value+1
            datatable()
        }
        
        const onSortChange = (params) => {
            sortColumn.value = params[0].field
            order.value = params[0].type
            datatable()
        }

        return {
            datatable, handleSearch, handlePageChange, handleChangePage, onSortChange, deleteButton,
            rows, searchTerm, start, row, totalRecords, sortColumn, order, isLoading
        }
    },
    components: {
        VueGoodTable,
        BRow, BCol, BCard, BCardText, BFormInput, BAvatar, BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardTitle, BCardBody, BButton, BFormGroup, BFormSelect
    },
    data() {
        return {
            columns: [
                {
                    label: 'No',
                    field: 'no',
                    width: '1%',
                },
                {
                    label: 'Kode',
                    field: 'kode',
                },
                {
                    label: 'Label',
                    field: 'label',
                },
                {
                    label: 'Penjelasan',
                    field: 'penjelasan',
                },
                {
                    label: 'Aksi',
                    field: 'aksi',
                    width: '1%',
                    sortable: false
                },
            ],
            title2: this.$title
        }
    },
    directives: {
        Ripple,
    },
    metaInfo: {
        titleTemplate: 'Morphology | %s'
    }
}
</script>

<style lang="scss" scope>
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>